import { ISocialLinks } from '../../types'
import { ShareIcon } from '../ShareIcon/ShareIcon'
import {
  Container,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from './ShareIconsFooter.styles'

export const ShareIconsFooter = ({
  socialLinks,
}: {
  socialLinks: ISocialLinks
}) => {
  const { link_to_twitter, link_to_instagram, link_to_facebook } = socialLinks

  return (
    <Container>
      {link_to_twitter && (
        <ShareIcon href={link_to_twitter} ariaLabel='twitter'>
          <TwitterIcon />
        </ShareIcon>
      )}
      {link_to_instagram && (
        <ShareIcon href={link_to_instagram} ariaLabel='instagram'>
          <InstagramIcon />
        </ShareIcon>
      )}
      {link_to_facebook && (
        <ShareIcon href={link_to_facebook} ariaLabel='facebook'>
          <FacebookIcon />
        </ShareIcon>
      )}
    </Container>
  )
}
