import Link from '../Link/Link'

type Props = {
  href: string
  ariaLabel: string
  children: React.ReactNode
}

export const ShareIcon = ({ href, ariaLabel, children }: Props) => {
  return (
    <Link href={href} target='_blank' ariaLabel={ariaLabel}>
      {children}
    </Link>
  )
}
