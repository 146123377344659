import { IFooter, IFooterColumn, IFooterLink, ISiteMeta } from '../../types'
import Link from '../Link/Link'
import {
  AppButton,
  AppButtonsContainer,
  BrandInfo,
  Column,
  ColumnContainer,
  ColumnList,
  Container,
  Separator,
  StyledLink,
  Wrapper,
} from './Footer.styles'
import { ShareIconsFooter } from './ShareIconsFooter'
import { LogoComp } from '../Logo'

const itemsPerCol = 6
const itemsPerMainCol = 3

const renderLinks = (footerLinks: IFooterLink[]) => {
  return (
    <ColumnList>
      {footerLinks?.slice(0, itemsPerCol).map(link => {
        if (!link) {
          return null
        }
        return (
          <li key={link.title}>
            <StyledLink href={link?.url} target={link.target}>
              {link.title}
            </StyledLink>
          </li>
        )
      })}
    </ColumnList>
  )
}

type MainColumnProps = {
  footerLinks: IFooterLink[]
  siteMeta: ISiteMeta
}

const MainFooterColumn = ({ footerLinks, siteMeta }: MainColumnProps) => {
  return (
    <Column>
      {footerLinks && renderLinks(footerLinks)}
      <BrandInfo>
        <LogoComp inFooter={true} />
        <ShareIconsFooter socialLinks={siteMeta.social_links} />
      </BrandInfo>
      <AppButtonsContainer>
        <Link href={siteMeta.links_to_app.ios} target='_blank'>
          <AppButton
            loading='lazy'
            src={'/assets/images/svg/app-store-button.svg'}
            alt='app-store-button'
          />
        </Link>
        <Link href={siteMeta.links_to_app.android} target='_blank'>
          <AppButton
            loading='lazy'
            src={'/assets/images/svg/google-play-button.svg'}
            alt='google-play-button'
          />
        </Link>
      </AppButtonsContainer>
    </Column>
  )
}

type ColumnProps = { column: IFooterColumn }
const FooterColumn = ({ column }: ColumnProps) => {
  if (!column || !column.links) {
    return null
  }
  return <Column>{renderLinks(column.links)}</Column>
}

type FooterProps = {
  footer: IFooter
  siteMeta: ISiteMeta
}
const Footer = ({ footer, siteMeta }: FooterProps) => {
  return (
    <Container id={'footerContainer'}>
      <Wrapper>
        <ColumnContainer>
          <MainFooterColumn
            footerLinks={footer.main?.slice(0, itemsPerMainCol)}
            siteMeta={siteMeta}
          />
          <Separator />
          <FooterColumn column={footer.footerOne} />
          <Separator />
          <FooterColumn column={footer.footerTwo} />
          <Separator />
          <FooterColumn column={footer.footerThree} />
          <Separator />
          <FooterColumn column={footer.footerFour} />
          {footer.footerFive && <Separator />}
          <FooterColumn column={footer.footerFive} />
        </ColumnContainer>
      </Wrapper>
    </Container>
  )
}
export default Footer
