import styled from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: none;
  position: relative;
  z-index: 1;

  ${device.medium} {
    display: initial;
  }
`

export const Wrapper = styled.div`
  margin: 0 auto;
  position: relative;

  &:before {
    content: ' ';
    z-index: -1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    background: ${colors.footerBlue};
    height: 100%;
    width: 100vw;

    ${themeClassName.vod} & {
      background: radial-gradient(circle at 54% 46%, #232323 38%, #121312 141%);
    }

    ${themeClassName.vodMain} & {
      background: radial-gradient(circle at 54% 46%, #232323 38%, #121312 141%);
    }
  }

  ${device.medium} {
    padding: 24px 0;
  }

  ${device.large} {
    padding: 24px 0;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 185px;
  text-align: center;

  :first-child {
    border-right: 0;
  }
`

export const Separator = styled.div`
  height: 185px;
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
`

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  ${device.medium} {
    max-width: 1260px;
  }

  ${device.large} {
    max-width: 1290px;
  }
`

export const BrandInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  margin-top: 37.44px;
  margin-bottom: 12px;
`

export const ColumnList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const StyledLink = styled(Link)`
  color: ${colors.white};
  font-size: 16px;
  line-height: 1.2;
`

export const Logo = styled.img`
  color: ${colors.white};
  width: 77px;

  ${props => {
    switch (props.theme.visible) {
      case 'mobile':
        return `
                  display: none;
                  ${device.small} {
                      display: block;
                  }
                `
      case 'desktop':
        return `
                  display: block;
                  ${device.small} {
                      display: none;
                  }
                `
    }
  }}
`

export const AppButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 14.25px;
`

export const AppButton = styled.img`
  height: 19.56px;
`
