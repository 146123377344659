import styled, { css } from 'styled-components'
import { colors, themeClassName } from '../../styles/GlobalVariables'
import Facebook from '/public/assets/images/svg/facebook.svg'
import Instagram from '/public/assets/images/svg/instagram.svg'
import Tiktok from '/public/assets/images/svg/tiktok.svg'
import Twitter from '/public/assets/images/svg/twitter.svg'
import Youtube from '/public/assets/images/svg/youtube.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  a {
    display: flex;
  }
`

const defaultStyle = css`
  font-size: 13px;
  path {
    fill: ${colors.yellow};
  }

  ${themeClassName.vod} & {
    path {
      fill: ${colors.pink};
    }
  }
`

export const FacebookIcon = styled(Facebook)`
  ${defaultStyle}
`
export const InstagramIcon = styled(Instagram)`
  ${defaultStyle}
`

export const TiktokIcon = styled(Tiktok)`
  ${defaultStyle}
`

export const TwitterIcon = styled(Twitter)`
  ${defaultStyle}
`

export const YoutubeIcon = styled(Youtube)`
  ${defaultStyle}
`
